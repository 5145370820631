import { DEFAULT_DURATION_IN_MINUTES } from './dates';
import { isNumeric } from './isNumeric';

export default class DateTimeUtil {
  static convertDurationPerSessionToNumericValue(durationPerSession) {
    try {
      if (durationPerSession && isNumeric(durationPerSession)) {
        const durationPerSessionNumeric = parseInt(durationPerSession);
        if (durationPerSessionNumeric && durationPerSessionNumeric >= DEFAULT_DURATION_IN_MINUTES) {
          return durationPerSessionNumeric;
        }
      }
    } catch (error) {
      console.error(`durationPerSession numeric error`, error);
    }
    return null;
  }
}
