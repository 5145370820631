import pick from 'lodash/pick';

import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  getGooglePlaceDetails,
  getRewards,
  nylasFindFreeBusyTimeslots,
  transactionLineItems,
} from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import {
  DEFAULT_DURATION_IN_MINUTES,
  findNextBoundary,
  getStartOf,
  monthIdString,
} from '../../util/dates';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { getProcess, isBookingProcessAlias } from '../../transactions/transaction';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import moment from 'moment';
import DateTimeUtil from '../../util/date-time.util';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_FREE_BUSY_TIME_SLOTS_SUCCESS =
  'app/ListingPage/FETCH_FREE_BUSY_TIME_SLOTS_SUCCESS';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const FETCH_REWARDS_SUCCESS = 'app/ListingPage/FETCH_REWARDS_SUCCESS';

export const FETCH_GOOGLE_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_GOOGLE_REVIEWS_SUCCESS';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const SET_DURATION_PER_SESSION_SUCCESS = 'app/ListingPage/SET_DURATION_PER_SESSION_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  googleReviews: null,
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2022-03': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  inquiryModalOpenForListingId: null,
  freeBusyTimeslotMap: new Map(),
  rewards: new Array(),
  userListingRefs: [],
  durationPerSession: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_GOOGLE_REVIEWS_SUCCESS:
      return { ...state, googleReviews: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_FREE_BUSY_TIME_SLOTS_SUCCESS:
      return { ...state, freeBusyTimeslotMap: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };

    case FETCH_REWARDS_SUCCESS:
      return { ...state, rewards: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };

    case SET_DURATION_PER_SESSION_SUCCESS:
      return { ...state, durationPerSession: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchFreeBusyTimeSlotsRequest = freeBusyTimeslotMap => ({
  type: FETCH_FREE_BUSY_TIME_SLOTS_SUCCESS,
  payload: freeBusyTimeslotMap,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

export const fetchRewardsSuccess = rewards => ({
  type: FETCH_REWARDS_SUCCESS,
  payload: rewards,
});

export const fetchGoogleReviewsSuccess = googleReviews => ({
  type: FETCH_GOOGLE_REVIEWS_SUCCESS,
  payload: googleReviews,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const setDurationPerSessionSuccess = durationPerSession => ({
  type: SET_DURATION_PER_SESSION_SUCCESS,
  payload: durationPerSession,
});

// ================ Thunks ================ //

export const showListing = (listingId, config, isOwn = false) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(data, sanitizeConfig));
      return data;
    })
    .then(data => {
      const place_id = data?.data?.data?.attributes?.publicData?.google?.place_id;
      if (place_id) {
        return getGooglePlaceDetails(place_id)
          .then(result => {
            if (result) {
              const url = result?.url;
              const rating = result?.rating;
              const user_ratings_total = result?.user_ratings_total;
              dispatch(
                fetchGoogleReviewsSuccess({
                  url,
                  averageRating: rating,
                  totalReviewCount: user_ratings_total,
                })
              ).then(() => {
                return data;
              });
            }
            return data;
          })
          .catch(err => {
            console.error(err);
            return data;
          });
      } else {
        return data;
      }
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

const filterTimeslots = async (timeSlots, freeBusyTimeslotsMap) => {
  if (freeBusyTimeslotsMap) {
    const timeslotsFiltered = [];
    for (let i = 0; i < timeSlots.length; i++) {
      const startTimeslot = moment(timeSlots[i].attributes.start).format('L');
      const freeBusySlot = await freeBusyTimeslotsMap.get(startTimeslot);
      if (freeBusySlot) {
        const firstFreeBusySlot = freeBusySlot[0];
        const startTimeslotUnix = moment(timeSlots[i].attributes.start).unix() * 1000;
        const endTimeslotUnix = moment(timeSlots[i].attributes.end).unix() * 1000;
        if (
          firstFreeBusySlot.startTime <= startTimeslotUnix &&
          firstFreeBusySlot.endTime >= endTimeslotUnix
        ) {
          continue;
        } else {
          timeslotsFiltered.push(timeSlots[i]);
        }
      } else {
        timeslotsFiltered.push(timeSlots[i]);
      }
    }
    return timeslotsFiltered.length > 0 ? timeslotsFiltered : timeSlots;
  } else {
    return timeSlots;
  }
};

export const fetchTimeSlots = (listingId, start, end, timeZone, freeBusyTimeslotsMap) => async (
  dispatch,
  getState,
  sdk
) => {
  const monthId = monthIdString(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(async timeSlots => {
      const timeslotsFiltered = await filterTimeslots(timeSlots, freeBusyTimeslotsMap);
      dispatch(fetchTimeSlotsSuccess(monthId, timeslotsFiltered));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const fetchFreeBusyTimeslots = async (dispatch, authorId) => {
  return await nylasFindFreeBusyTimeslots({ authorId })
    .then(async data => {
      if (data && data.length) {
        const freeBusyTimeslotMap = new Map();
        for (let i = 0; i < data.length; i++) {
          const { startDateKey } = data[i];
          const innerTimeslotsArr = freeBusyTimeslotMap.get(startDateKey);
          if (innerTimeslotsArr) {
            innerTimeslotsArr.push(data[i]);
            freeBusyTimeslotMap.set(startDateKey, innerTimeslotsArr);
          } else {
            const newInnerArr = new Array();
            newInnerArr.push(data[i]);
            freeBusyTimeslotMap.set(startDateKey, newInnerArr);
          }
        }
        await dispatch(fetchFreeBusyTimeSlotsRequest(freeBusyTimeslotMap));
        return freeBusyTimeslotMap;
      } else {
        return new Map();
      }
    })
    .catch(err => {
      console.error(err);
      return new Map();
    });
};

export const sendInquiry = (listing, message) => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const listingId = listing?.id;
  const [processName, alias] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;

  const bodyParams = {
    transition: transitions.INQUIRE,
    processAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendInquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing, freeBusyTimeslots) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const unitType = attributes?.publicData?.unitType;
    const timeUnit = unitType === 'hour' ? 'hour' : 'day';

    const nextBoundary = findNextBoundary(new Date(), timeUnit, tz);

    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz, freeBusyTimeslots)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz, freeBusyTimeslots)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

const fetchMonthlyTimeSlotsSessionBooking = (
  dispatch,
  listing,
  freeBusyTimeslots,
  currentCycle
) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    //const unitType = attributes?.publicData?.unitType;
    const timeUnit = 'day'; // 'hour'; // unitType === 'hour' ? 'hour' : 'day';

    const durationPerSession = attributes?.publicData?.durationPerSession;
    const durationPerSesionNumericValue = DateTimeUtil.convertDurationPerSessionToNumericValue(
      durationPerSession
    );
    const durationPerSessionNumeric = durationPerSesionNumericValue
      ? durationPerSesionNumericValue
      : DEFAULT_DURATION_IN_MINUTES;

    dispatch(setDurationPerSessionSuccess(durationPerSessionNumeric));

    const cycleStartDate = currentCycle?.startDate;
    const cycleEndDate = currentCycle?.endDate;

    const cycleStartDateStartOfDay = getStartOf(cycleStartDate, 'day', tz);
    const cycleEndDateStartOfDay = getStartOf(cycleEndDate, 'day', tz, 1);
    const todayStartOfDay = findNextBoundary(new Date(), timeUnit, tz, durationPerSessionNumeric);

    const diffStartBoundary = moment(cycleStartDateStartOfDay).diff(todayStartOfDay, 'days', true);
    const startBoundary = diffStartBoundary > 0 ? cycleStartDateStartOfDay : todayStartOfDay;

    const nextBoundary = findNextBoundary(startBoundary, timeUnit, tz, durationPerSessionNumeric);
    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz, freeBusyTimeslots)),
      dispatch(
        fetchTimeSlots(listing.id, nextMonth, cycleEndDateStartOfDay, tz, freeBusyTimeslots)
      ),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

export const fetchRewards = () => dispatch => {
  getRewards()
    .then(rewards => {
      if (rewards) {
        dispatch(fetchRewardsSuccess(rewards));
      }
    })
    .catch(e => {
      log.error(e, 'fetching-rewards-failed');
    });
};

export const loadTimeslotsForCustomOffer = listing => async dispatch => {
  const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
  if (isBookingProcessAlias(transactionProcessAlias)) {
    const { author } = listing;
    const authorId = author.id.uuid;
    const freeBusyTimeslots = await fetchFreeBusyTimeslots(dispatch, authorId);
    fetchMonthlyTimeSlots(dispatch, listing, freeBusyTimeslots);
  }
};

export const loadTimeslotsForSessionBooking = (listing, currentCycle) => async dispatch => {
  const { author } = listing;
  const authorId = author.id.uuid;
  const freeBusyTimeslots = await fetchFreeBusyTimeslots(dispatch, authorId);
  fetchMonthlyTimeSlotsSessionBooking(dispatch, listing, freeBusyTimeslots, currentCycle);
};

export const queryUserListings = (userId, config) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      author_id: userId,
      pub_listingType: 'package-subscription',
      include: ['images'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      const listings = response.data.data;
      const images = response.data.included;
      for (let i = 0; i < listings.length; i++) {
        listings[i].images = [images[i]];
      }
      dispatch(queryListingsSuccess(listings));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const loadData = (params, search, config) => dispatch => {
  const listingId = new UUID(params.id);

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, config, true));
  }

  return Promise.all([
    dispatch(showListing(listingId, config)),
    dispatch(fetchReviews(listingId)),
    dispatch(fetchRewards()),
  ]).then(async response => {
    const listing = response[0].data.data;
    const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
    if (isBookingProcessAlias(transactionProcessAlias)) {
      const { author } = listing.relationships;
      const authorId = author.data.id.uuid;
      const freeBusyTimeslots = await fetchFreeBusyTimeslots(dispatch, authorId);
      // Fetch timeSlots.
      // This can happen parallel to loadData.
      // We are not interested to return them from loadData call.
      fetchMonthlyTimeSlots(dispatch, listing, freeBusyTimeslots);

      const listingType = listing?.attributes?.publicData?.listingType;
      if (listingType !== 'package-subscription') {
        dispatch(queryUserListings(authorId, config));
      }
    }
    return response;
  });
};
