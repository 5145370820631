import { useSelector } from 'react-redux';
import css from '../ListingPage.module.css';
import { Heading, ListingCard } from '../../../components';
import { FormattedMessage } from 'react-intl';

export const SectionPackageListings = () => {
  const state = useSelector(state => state.ListingPage);
  const { userListingRefs } = state;
  const listings = userListingRefs;
  const hasListings = listings.length > 0;

  return (
    <div className={css.sectionReviews}>
      {hasListings ? (
        <div className={css.listingsContainer}>
          <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
            <FormattedMessage
              id="ListingPage.packageListingsTitle"
              values={{ count: listings.length }}
            />{' '}
          </Heading>
          <ul className={css.listings}>
            {listings.map(l => (
              <li className={css.listing} key={l.id.uuid}>
                <ListingCard listing={l} showAuthorInfo={false} />
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
export default SectionPackageListings;
